import Vue from "vue";
import Router from "vue-router";
import Layout from "@/pages/layout/layout.vue";
import Login from "@/pages/login";

Vue.use(Router);

var router = new Router({
  routes: [
    // 刚进入页面时，进入homePage页面
    // {
    //   path: "/",
    //   redirect: "/homePage"
    // },
    {
      path: "/",
      redirect: "/login"
    },
    // 输入不匹配此页面中定义的路由时，跳转到homePage页面
    // 防止在导航栏随便输入，进入空白页，这里重定向到homePage页面
    {
      path: "*",
      redirect: "/login"
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    // 侧边栏 首页 路由地址
    {
      path: "/",
      name: "Layout",
      component: Layout,
      children: [
        {
          path: "homePage",
          name: "homePage",
          component: () => import("@/pages/home/homePage")
        }
      ]
    },
	// 业务管理
	{
	  path: "/",
	  name: "Layout",
	  component: Layout,
	  children: [
	    {
	      path: "addoffer",//新增报价
	      name: "addoffer",
	      component: () => import("@/pages/businessManage/addOffer")
	    },
		{
		  path: "offermanage",//报价管理
		  name: "offermanage",
		  component: () => import("@/pages/businessManage/offerManage")
		},
		{
		  path: "businessdetail",//详情
		  name: "businessdetail",
		  component: () => import("@/pages/businessManage/businessDetail")
		},
		{
		  path: "pricesheet",//报价单
		  name: "pricesheet",
		  component: () => import("@/pages/businessManage/priceSheet")
		},
		{
		  path: "ordermanage",//订单管理
		  name: "ordermanage",
		  component: () => import("@/pages/businessManage/orderManage")
		},
		{
		  path: "ordermanagedetail",//订单详情
		  name: "ordermanagedetail",
		  component: () => import("@/pages/businessManage/orderManageDetail")
		}
	  ]
	},
	// 跟单管理
    {
      path: "/",
      name: "Layout",
      component: Layout,
      // 三个 子路由 路由地址
      children: [
        {
          path: "inproduction",//生产中
          name: "inproduction",
          component: () => import("@/pages/withOrderManage/inProduction")
        },
		{
		  path: "inproductiondetail",//生产中
		  name: "inproductiondetail",
		  component: () => import("@/pages/withOrderManage/inProductionDetail")
		},
		{
		  path: "finish",//已完工
		  name: "finish",
		  component: () => import("@/pages/withOrderManage/finish")
		},
		
      ]
    },
	// 系统设置
	{
	  path: "/",
	  name: "Layout",
	  component: Layout,
	  // 三个 子路由 路由地址
	  children: [
	    {
	      path: "setsystem",//生产中
	      name: "setsystem",
	      component: () => import("@/pages/setSystem/setSystem")
	    }
	  ]
	},
	// 加工参数
	{
	  path: "/",
	  name: "Layout",
	  component: Layout,
	  // 三个 子路由 路由地址
	  children: [
	    {
	      path: "materialsmanage",//材料管理
	      name: "materialsmanage",
	      component: () => import("@/pages/processParams/materialsManage")
	    },
		{
		  path: "surfacedispose",//表面处理
		  name: "surfacedispose",
		  component: () => import("@/pages/processParams/surfaceDispose")
		},
	  ]
	},
	// 账号管理
	{
	  path: "/",
	  name: "Layout",
	  component: Layout,
	  // 三个 子路由 路由地址
	  children: [
	    {
	      path: "usermanage",//用户管理
	      name: "usermanage",
	      component: () => import("@/pages/accountManage/userManage")
	    },
	    {
	      path: "supermanage",//删除统计
	      name: "supermanage",
	      component: () => import("@/pages/accountManage/superManage"),
	    }
	  ]
	},
  ]
});

//解决重复点击路由时，控制台报错
//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

// router.beforeEach((to, from, next) => {
// })

// 全局前置守卫
// 当一个导航触发时，全局前置守卫按照创建顺序调用。
// 守卫是异步解析执行，此时导航在所有守卫 resolve 完之前一直处于 等待中
router.beforeEach((to, from, next) => {
  // 想办法获取token，一般都是存储在vuex中
  let isLogin = window.localStorage.getItem("token");
  // 存在token就让他登录，跳转到登录页写好的跳转地址
  if (isLogin) {
    next();
  }
  // 否则就让他跳转到登录页
  else {
    if (to.path === "/login") {
      next();
    } else {
      alert("没有访问权限或登录已过期，请重新登录！");
      next("/login");
    }
  }
});

export default router;
