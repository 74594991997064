import axios from 'axios';
import LogUtils from "./LogUtils";
import StorageUtils from './StorageUtils';
import * as Url from "./Url";
import router from '../router/index'

axios.defaults.timeout = 15000;// 请求超时时间
axios.defaults.baseURL = Url.HOST

//使用create方法创建axios实例
let instance = axios.create({
    baseURL: Url.HOST,
    headers: {'content-type': 'multipart/form-data'},
});

let jsonReq = axios.create();

//http net 添加请求拦截器
axios.interceptors.request.use(config => {
        // config.headers.token = sessionStorage.getItem('loginToken')
        // config.headers.token = StorageUtils.getStringData("loginToken")
        // config.headers.Authorization
        // config.headers['Content-Type'] = 'application/json;charset=utf-8'
        // config.method.toUpperCase() === 'POST'
        config.data = JSON.stringify(config.data);
        config.headers = {
			'Content-Type': 'application/json; charset=utf-8',
			'token': StorageUtils.getStringData("token") || '',
        }
        return config;
    },
    error => {
        LogUtils.log('请求拦截器 error = ' + error)
        return Promise.reject(error);
    }
);

//添加响应拦截器    异常处理
axios.interceptors.response.use(
    response => {
        LogUtils.log('响应拦截器状态 ：' + response.status)
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    }, err => {
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    LogUtils.log('错误请求')
                    break;
                case 401:
                    router.push('/login')
                    LogUtils.log('未授权，请重新登录')
                    break;
                case 403:
                    LogUtils.log('拒绝访问')
                    break;
                case 404:
                    LogUtils.log('请求错误,未找到该资源')
                    break;
                case 405:
                    LogUtils.log('请求方法未允许')
                    break;
                case 408:
                    LogUtils.log('请求超时')
                    break;
                case 500:
                    LogUtils.log('服务器端出错')
                    break;
                case 501:
                    LogUtils.log('网络未实现')
                    break;
                case 502:
                    LogUtils.log('网络错误')
                    break;
                case 503:
                    LogUtils.log('服务不可用')
                    break;
                case 504:
                    LogUtils.log('网络超时')
                    break;
                case 505:
                    LogUtils.log('http版本不支持该请求')
                    break;
                default:
                    LogUtils.log(`连接错误${err.response.status}`)
            }
        } else {
            LogUtils.log('连接到服务器失败')
        }
        return Promise.resolve(err.response)
    })


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function getRequest(url, params = {}, proxy) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(response => {
            resolve(response.data);
            // LogUtils.log("==================== GET Request success Start =======================")
            // LogUtils.log("url = " + url);
            // LogUtils.log("params = " + params);
            // LogUtils.log("responeData = " + JSON.stringify(response.data))
            // LogUtils.log("==================== GET Request success End =========================")
        }).catch(err => {
            reject(err)
            // LogUtils.log("==================== GET Request error Start =======================")
            // LogUtils.log("url = " + url);
            // LogUtils.log("params = " + params);
            // LogUtils.log("respone error = " + err)
            // LogUtils.log("==================== GET Request error End =========================")
        })
    })
}

/**
 * JSON请求
 */
export function postJsonRequest(url, data = {}, proxy) {
    return new Promise((resolve, reject) => {
        jsonReq.post(url, data).then(response => {
            resolve(response.data);
            LogUtils.log("==================== POST JSON Request success Start =======================")
            LogUtils.log("url = " + url);
            LogUtils.log("requestParams = " + JSON.stringify(data));
            LogUtils.log("responeData = " + JSON.stringify(response.data))
            LogUtils.log("==================== POST JSON Request success End =========================")
        }, err => {
            reject(err)
            LogUtils.log("==================== POST JSON Request error Start =======================")
            LogUtils.log("url = " + url);
            LogUtils.log("requestParams = " + JSON.stringify(data));
            LogUtils.log("respone error = " + err)
            LogUtils.log("==================== POST JSON Request error End =========================")
        })
    })
}

/**
 * 表单请求
 * @param url
 * @param data
 * @returns {Promise<any>}
 */
export function postFormRequest(url, data = {}, proxy) {
    return new Promise((resolve, reject) => {
        instance.post(url, data, {
            headers: {
                'token': StorageUtils.getStringData("loginToken"),
                // 'token':  'Bearer ' +StorageUtils.getStringData("loginToken"),
            }
        }).then(response => {
            resolve(response.data);
            LogUtils.log("==================== POST Form Request success Start =======================")
            LogUtils.log("url = " + url);
            LogUtils.log("requestParams = ");
            for (let [key, value] of data) {
                LogUtils.log("      " + key + ":" + value);
            }
            LogUtils.log("responeData = " + JSON.stringify(response.data))
            LogUtils.log("==================== POST Form Request success End =========================")
        }, err => {
            reject(err)
            LogUtils.log("==================== POST Form Request error Start =======================")
            LogUtils.log("url = " + url);
            LogUtils.log("requestParams = ");
            for (let [key, value] of data) {
                LogUtils.log("      " + key + ":" + value);
            }
            LogUtils.log("respone error = " + JSON.stringify(err))
            LogUtils.log("==================== POST Form Request error End =========================")
        })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patchRequest(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

// post 请求
export function postRequest(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.post(url, data).then(response => {
			resolve(response.data);
		}).catch(err => {
			reject(err);
		});
	});
}


/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function putRequest(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}