<template>
	<div class="login">
		
		<div class="login_form_box">
			<div class="login_name">
				{{loginType?'XXX公司':'注册'}}
			</div>
			<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
				<el-form-item prop="mobile">
					<el-input v-model="loginForm.mobile" type="text" auto-complete="off" placeholder="请输入手机号">
					</el-input>
				</el-form-item>
				<el-form-item prop="password">
					<el-input v-model="loginForm.password" :type="loginPadType" auto-complete="off" placeholder="请输入密码"
						@keyup.enter.native="handleLogin">
					</el-input>
				</el-form-item>
				<!-- <el-form-item>
					<div class="save_pad_box">
						<el-checkbox v-model="checked">
							<span class="save_pad">记住密码</span>
						</el-checkbox>
						<div class="save_pad" @click="loginType=!loginType">
							{{loginType?'用户注册':'登 录'}}
						</div>
					</div>
				</el-form-item> -->


				<el-form-item style="width:100%;">
					<el-button :loading="loading" size="medium" type="primary" class="submit_btn"
						@click.native.prevent="handleLogin">
						<span v-if="!loading">{{loginType?'登 录':'注 册'}}</span>
						<span v-else>登 录 中...</span>
					</el-button>
					<div style="float: right;" v-if="register">
						<router-link class="link-type" :to="'/register'">立即注册</router-link>
					</div>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>
<script>
	import StorageUtils from '../../utils/StorageUtils';
	export default {
		data() {
			return {
				loginType:true,
				codeUrl: "",
				loginPadType: 'password', //控制登录密码是否显示可识别文字
				loginForm: {
					mobile: "",
					password: "",
				},
				loginRules: {
					mobile: [{
						required: true,
						trigger: "blur",
						message: "请输入您的手机号"
					}],
					password: [{
						required: true,
						trigger: "blur",
						message: "请输入您的密码"
					}],
				},
				loading: false,
				// 验证码开关
				captchaEnabled: true,
				// 注册开关
				register: false,
				redirect: undefined,
				checked: false,
			}
		},
		methods: {
			async handleLogin() {
				this.$refs.loginForm.validate(valid => {
					if (valid) {
						this.loading = true;
						if(this.loginType){
							this.login()
						}else{
							this.registerReuqest()
						}
					}
				});
			},
			login(){
				this.$api.login({
					mobile: this.loginForm.mobile,
					password: this.loginForm.password,
				}).then(res => {
					if(res.code == 1){
						StorageUtils.setStringData("token", res.data.userinfo.token)
						StorageUtils.setStringData("userinfo", JSON.stringify(res.data.userinfo))
						this.$router.push({
							name: 'homePage'
						})
					}else{
						this.$use.msg(res.message,'error')
						this.loading = false;
					}
				})
				.catch(err=>{
					this.loading = false;
				})
			},
			registerReuqest(){
				this.$api.registerReuqest(this.loginForm).then(res => {
					if(res.code == 1){
						this.$use.msg("注册成功，请等待审核",'success')
						this.loading = false;
					}else{
						this.$use.msg("注册提交失败",'error')
						this.loading = false;
					}
				})
				.catch(err=>{
					this.loading = false;
				})
				
			},
			
			
			
		},
	}
</script>
<style rel="stylesheet/scss" lang="scss">
	@import url("../../assets/styles/login.scss");

	.login {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 100%;
		background-image: url('../../assets/images/login_bg.png');
		background-size: cover;
	}
	
	.title {
		margin: 0px auto 30px auto;
		text-align: center;
		color: #707070;
	}
	
	.login_form_box{
		position: relative;
	}
	
	.login_name{
		position: absolute;
		top: -20%;
		font-size: 36px;
		font-weight: bold;
	}

	.login-form {
		border-radius: 0.25rem;
		padding: 3.125rem 0 1.25rem;

		.el-input {
			height: 2.875rem;
			width: 24.375rem;
			text-align: center;
			margin: 0 0 0.625rem;

			input {
				width: 24.375rem;
				height: 2.875rem;
				font-size: 1rem;
				background-color: #fff;
				font-weight: bold;
				color: #333333;
			}
		}

		.el-input__inner::placeholder {
			color: #777777;
			font-size: 1rem;
			font-weight: bold;
		}

		.input-icon {
			height: 2.75rem;
			width: 14px;
			margin-left: 2px;
		}
	}

	.login-tip {
		font-size: 13px;
		text-align: center;
		color: #bfbfbf;
	}

	.login-code {
		width: 33%;
		height: 38px;
		float: right;

		img {
			cursor: pointer;
			vertical-align: middle;
		}
	}

	// .el-login-footer {
	//   height: 40px;
	//   line-height: 40px;
	//   position: fixed;
	//   bottom: 0;
	//   width: 100%;
	//   text-align: center;
	//   color: #fff;
	//   font-family: Arial;
	//   font-size: 12px;
	//   letter-spacing: 1px;
	// }
</style>